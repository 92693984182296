import React from 'react'
import { _index } from './searchClient'
import { Link, useNavigate } from 'react-router-dom'

function AdminSearch() {

    const [search, setSearch] = React.useState('')
    const [results, setResults] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    const navigate = useNavigate()

    React.useEffect(() => {
        if (search.length === 0) {
            setResults([])
            return
        }

        setLoading(true)
        _index
            .search(search, {
                limit: 5,
            })
            .then(({ hits }) => {
                setResults(hits)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [search])

    const goToResults = () => {
        navigate(`/advanced-search?query=${search}`)
    }

    return (
        <div className="justify-center bg-slate-100 flex flex-col -mr-5 mt-7 pl-12 pr-20 py-12 rounded-[30px] max-md:max-w-full max-md:px-5">
            <h1
                className="text-violet-950 text-center text-4xl leading-[112.5%] self-center max-md:text-4xl"
                style={{
                    color: '#272969',
                    fontFamily: 'Didonesque',
                }}
            >Treaty Database of the Government of Uganda</h1>
            <div className="flex items-center justify-center mt-10 relative">
                <input
                    placeholder='Search Treaty'
                    className="outline-none w-60 sm:w-[50%] h-14 rounded-md text-base px-5"
                    onChange={(e) => setSearch(e.target.value)}
                />
                <button
                    onClick={goToResults}
                    className="text-white text-base bg-brightRed leading-[150%] self-stretch justify-end items-center bg-violet-950 w-[97px] max-w-full px-5 py-2 rounded-md ml-2"
                    style={{
                        background: '#272969',
                    }}
                >Search</button>
                {
                        search.length > 0 && (
                            results.length > 0 ? (
                                <div className="absolute top-2 z-50 bg-white w-[70%] md:w-[60%] mt-14 rounded-md shadow-md">
                                    {
                                        loading ? (
                                            <div className="flex items-center justify-between px-5 py-2">
                                                <div className="text-sm text-gray-500">Searching...</div>
                                            </div>
                                        ) : (
                                            results.map((result) => (
                                                <Link
                                                    to={`/treaties/${result.id}`}
                                                    className="flex items-center justify-between px-5 py-2 hover:bg-gray-100"
                                                    key={result.treatyTitle}
                                                >
                                                    <div className="text-sm text-gray-500">{result.treatyTitle || ''}</div>
                                                    {/* <div className="text-sm text-gray-500">{result.ratificationDate || ''}</div> */}
                                                </Link>
                                            ))
                                        )
                                    }
                                </div>
                            ) : (
                                <div className="absolute top-2 z-50 w-[60%] bg-white mt-14 rounded-md shadow-md">
                                    <div className="flex items-center justify-between px-5 py-2">
                                        {
                                            loading ? (
                                                <div className="text-sm text-gray-500">Searching...</div>
                                            ) : (
                                                <div className="text-sm text-gray-500">No results found</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        )
                    }
            </div>
            <Link
                className="text-violet-950 text-base text-center leading-6 whitespace-nowrap mt-7 max-md:max-w-full"
                to={'/advanced-search'}
            >
                Advanced Search
            </Link>
        </div>
    )
}

export default AdminSearch