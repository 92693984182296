import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Router from './Routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { s3Config } from './configs';

function App() {
  
  useEffect(() => {
    console.log("s3Config ---- :",s3Config)
    console.log("process.env.REACT_APP_A_K_I ---- :", process.env.REACT_APP_A_K_I)
    console.log("process.env.REACT_APP_S_A_K ---- :", process.env.REACT_APP_S_A_K)
  }, [])

  return (
    <BrowserRouter>
      <Router />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
