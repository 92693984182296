import {
    Route,
    Routes,
    Navigate
} from "react-router-dom";

import Search from "./containers/Search";
import AdvancedSearch from "./containers/AdvancedSearch";
import TreatyDesc from "./containers/TreatyDesc";
import LoginForm from "./containers/Login";
import Dashboard from "./containers/Dashboard";
import Treaties from "./containers/Treaties";
import DasboardContent from "./containers/DasboardContent";
import AdminSearch from "./containers/AdminSearch";
import AdminUpload from "./containers/AdminUpload";


const Router = () => (
    <Routes>
        <Route exact path="/" element={<Search />} />
        <Route exact path="/advanced-search" element={<AdvancedSearch />} />
        <Route exact path="/treaties/:id" element={<TreatyDesc />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/dashboard" element={<Dashboard />}>
            <Route path="info" element={<DasboardContent />} />
            <Route path='/dashboard/treaties' element={ <Navigate to="/dashboard/treaties/search" /> }/>
            <Route path="treaties" element={<Treaties />}>
                <Route path="search" element={<AdminSearch />} />
                <Route path="upload" exact element={<AdminUpload />} />
            </Route>
        </Route>
        <Route path="*"
            element={
                <h1 className="h-screen w-screen flex justify-center items-center text-5xl font-bold font-serif">
                    404 PAGE not Found
                </h1>
            }
        />
    </Routes>
)

export default Router
