import React from 'react'
import { Link } from 'react-router-dom'
import logo2 from '../ulrc_logo.png';
import court from '../image_ug.png';
import logo from '../ulrc_logo1.png';

export function UnAuthHeader() {
    return (
        <div className='py-5 flex justify-between border-b-2'>
            <div className='flex space-x-2 md:space-x-7 items-center'>
                <img src={logo2} alt="logo" className='w-20 sm:w-40' />
                <img src={court} alt="logo" className='w-8 sm:w-16' />
            </div>
            <div className='flex space-x-2 sm:space-x-7 items-center'>                
                <Link to={'login'} className='bg-brightRed text-white w-24 h-10 rounded-3xl flex items-center justify-center text-base sm:text-sm'>Login</Link>
            </div>
        </div>
    )
}

export function UnAuthHeader2() {
    return (
        <div className='py-5 flex justify-between border-b-2'>
            <div className='flex space-x-7 items-center'>
                <img src={logo} alt="logo" className='w-40' />
                <img src={court} alt="logo" className='w-16' />
            </div>
            <div className='flex space-x-7 items-center'>
                <Link to={'/choose-user-type'} className='text-white'>Login</Link>
                <Link to={'/choose-user'} className='bg-brightRed text-white w-24 h-10 rounded-3xl flex items-center justify-center'>Sign Up</Link>
            </div>
        </div>
    )
}
