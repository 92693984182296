import React from "react";
import { UnAuthHeader, UnAuthHeader2 } from "./UnAuthHeader";
import bgImg from '../bg.png';
import docImg1 from '../image15.png';
import docImg2 from '../image16.png';
// import bgImg from '../bg_image.png';
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { _index } from "./searchClient";

function Search() {

    const [search, setSearch] = React.useState('')
    const [results, setResults] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    const navigate = useNavigate()

    React.useEffect(() => {
        if (search.length === 0) {
            setResults([]) 
            return
        }

        setLoading(true)
        _index
            .search(search, {
                limit: 5,
            })
            .then(({ hits }) => {
                setResults(hits)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [search])

    const goToResults = () => {
        navigate(`/advanced-search?query=${search}`)
    }

    return (
        <div style={{
            backgroundSize: '100%',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
            height: '100vh'
        }}
        // className='bg-hero-image w-full h-screen bg-cover bg-center'
        className='md:bg-hero-image'
        >
            <div className='pt-7 pb-20 md:container mx-3 md:mx-auto'>
                <UnAuthHeader />
            </div>
            <div className='md:container md:mx-auto justify-center items-center self-stretch flex flex-col px-5 py-20 rounded-[30px] relative'>
                
                <h1
                    className="text-violet-950 text-center text-4xl leading-[112.5%] self-center max-md:text-4xl"
                    style={{
                        color: '#272969',
                        fontFamily: 'Didonesque',
                    }}
                >Treaty Database of the Government of Uganda</h1>
                {/* <p className="text-neutral-400 text-center text-base leading-[150%] self-center mt-7 max-md:max-w-full">Law Reform for Good Governance and Sustainable Development.</p> */}
                {/* Render the auuto suggestions under the search bar */}
                <div className="flex items-center justify-center mt-10 border relative">
                    <input
                        placeholder='Search Treaty'
                        className="outline-none w-60 sm:w-80 h-14 rounded-md text-base px-5"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button
                        onClick={goToResults}
                        className="text-white text-base bg-brightRed leading-[150%] self-stretch justify-end items-center bg-violet-950 w-[97px] max-w-full px-5 py-2 rounded-md ml-2"
                        style={{
                            background: '#272969',
                        }}
                    >Search</button>
                    {
                        search.length > 0 && (
                            results.length > 0 ? (
                                <div className="absolute top-2 z-50 bg-white w-full mt-14 rounded-md shadow-md">
                                    {
                                        loading ? (
                                            <div className="flex items-center justify-between px-5 py-2">
                                                <div className="text-sm text-gray-500">Searching...</div>
                                            </div>
                                        ) : (
                                            results.map((result) => (
                                                <Link
                                                    to={`/treaties/${result.id}`}
                                                    className="flex items-center justify-between px-5 py-2 hover:bg-gray-100"
                                                    key={result.treatyTitle}
                                                >
                                                    <div className="text-sm text-gray-500">{result.treatyTitle || ''}</div>
                                                    {/* <div className="text-sm text-gray-500">{result.ratificationDate || ''}</div> */}
                                                </Link>
                                            ))
                                        )
                                    }
                                </div>
                            ) : (
                                <div className="absolute top-2 z-50 w-full bg-white mt-14 rounded-md shadow-md">
                                    <div className="flex items-center justify-between px-5 py-2">
                                        {
                                            loading ? (
                                                <div className="text-sm text-gray-500">Searching...</div>
                                            ) : (
                                                <div className="text-sm text-gray-500">No results found</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>

                <Link
                    to={'/advanced-search'}
                    className="text-violet-950 text-base leading-[150%] self-center mt-7 max-md:mb-2.5 hover:underline"
                    style={{
                        color: '#272969',
                    }}
                >Advanced Search</Link>
            </div>

            <div
                className='md:container mx-auto justify-center items-center border border-white self-center relative flex w-full max-w-[1191px] flex-col mt-40 px-5 py-14 rounded-[30px] border-solid max-md:max-w-full max-md:mt-10 '
                style={{
                    background: 'rgba(233, 233, 239)'
                }}
            >
                <h2 className="text-gray-900 text-center text-4xl font-medium leading-[108.333%] self-center font-serif">
                    Subscribe to our Email List 
                </h2>
                <p className="text-gray-900 text-center text-base leading-[150%] self-center mt-7">
                    Get updates on our latest resources
                </p>
                <div className="self-center flex w-[509px] max-w-full items-start gap-0 mt-7 max-md:flex-wrap">
                    <label htmlFor="email" className="sr-only">
                        Email Address
                    </label>
                    <input
                        type="email"
                        id="email"
                        className="outline-none text-xl items-center bg-white self-stretch mr-0 grow basis-auto px-5 py-2 rounded-2xl max-md:max-w-full"
                        placeholder="Email Address"
                    />
                    <button
                        type="submit"
                        className="text-white text-base mt-2 sm:mt-0 leading-[150%] self-stretch justify-end items-center bg-red-600 w-[120px] max-w-full p-3 md:p-5 rounded-2xl ml-2"
                    >
                        Subscribe
                    </button>
                </div>
            </div>

            <footer className="bg-bgBlue self-stretch relative flex flex-col mt-8 pt-14 pb-20 px-5 max-md:max-w-full max-md:mt-10">
                <div className="md:container mx-auto">
                    <hr className="bg-zinc-400 bg-opacity-60 self-center h-[1px] max-md:max-w-full" />
                    <div className="self-center md:flex w-full  items-start md:justify-between gap-5 mt-7">
                        <div className="text-white text-base opacity-80">
                            Copyright &copy; 2023 Uganda Law Reform Commission.
                        </div>
                        <div className="flex gap-3 md:gap-5 mt-7 md:mt-0 flex-col md:flex-row text-center">
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Partners
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                About Treaty DB
                            </a>
                            
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Terms of Use
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Search;