import React, { useEffect, useState } from 'react'
import logo2 from '../ulrc_logo.png';
import court from '../image_ug.png';
import { AiFillCloseSquare } from 'react-icons/ai';
import { FiChevronDown, FiChevronUp, FiFileText } from 'react-icons/fi';
import { CiLocationOn } from 'react-icons/ci';
import { SlLocationPin } from 'react-icons/sl';
import { PiUsersThree } from 'react-icons/pi';
import { MdBalance } from 'react-icons/md';
import { FaFilter } from "react-icons/fa6";
// import { AiFillCloseSquare } from "react-icons/ai";
import Select from 'react-select';
import { Await, Link, useLocation } from 'react-router-dom';
import MeiliSearch from 'meilisearch';
import TreatyImg from '../assets/treaty.png';
import { _index } from './searchClient';

export const cities = [
    {
        value: "New York City, USA",
        label: "New York City, USA"
    },
    {
        value: "Los Angeles, USA",
        label: "Los Angeles, USA"
    },
    {
        value: "Chicago, USA",
        label: "Chicago, USA"
    },
    {
        value: "London, UK",
        label: "London, UK"
    },
    {
        value: "Paris, France",
        label: "Paris, France"
    },
    {
        value: "Berlin, Germany",
        label: "Berlin, Germany"
    },
    {
        value: "Tokyo, Japan",
        label: "Tokyo, Japan"
    },
    {
        value: "Beijing, China",
        label: "Beijing, China"
    },
    {
        value: "Shanghai, China",
        label: "Shanghai, China"
    },
    {
        value: "Mumbai, India",
        label: "Mumbai, India"
    },
    {
        value: "Delhi, India",
        label: "Delhi, India"
    },
    {
        value: "Sydney, Australia",
        label: "Sydney, Australia"
    },
    {
        value: "Melbourne, Australia",
        label: "Melbourne, Australia"
    },
    {
        value: "Rio de Janeiro, Brazil",
        label: "Rio de Janeiro, Brazil"
    },
    {
        value: "São Paulo, Brazil",
        label: "São Paulo, Brazil"
    },
    {
        value: "Moscow, Russia",
        label: "Moscow, Russia"
    },
    {
        value: "Istanbul, Turkey",
        label: "Istanbul, Turkey"
    },
    {
        value: "Dubai, UAE",
        label: "Dubai, UAE"
    },
    {
        value: "Cairo, Egypt",
        label: "Cairo, Egypt"
    },
    {
        value: "Johannesburg, South Africa",
        label: "Johannesburg, South Africa"
    },
    {
        value: "Lagos, Nigeria",
        label: "Lagos, Nigeria"
    },
    {
        value: "Nairobi, Kenya",
        label: "Nairobi, Kenya"
    },
    {
        value: "Buenos Aires, Argentina",
        label: "Buenos Aires, Argentina"
    },
    {
        value: "Mexico City, Mexico",
        label: "Mexico City, Mexico"
    },
    {
        value: "Toronto, Canada",
        label: "Toronto, Canada"
    },
    {
        value: "Vancouver, Canada",
        label: "Vancouver, Canada"
    },
    {
        value: "Jakarta, Indonesia",
        label: "Jakarta, Indonesia"
    },
    {
        value: "Seoul, South Korea",
        label: "Seoul, South Korea"
    },
    {
        value: "Singapore, Singapore",
        label: "Singapore, Singapore"
    },
    {
        value: "Bangkok, Thailand",
        label: "Bangkok, Thailand"
    }
]
const treatySubjects = [
    {
        value: "agriculture",
        label: "Agriculture"
    },
    {
        value: "education",
        label: "Education"
    },
    {
        value: "health",
        label: "Health"
    },
    {
        value: "trade",
        label: "Trade"
    },
    {
        value: "environment",
        label: "Environment"
    },
    {
        value: "humanRights",
        label: "Human Rights"
    },
    {
        value: "labor",
        label: "Labor"
    },
    {
        value: "finance",
        label: "Finance"
    },
    {
        value: "energy",
        label: "Energy"
    },
    {
        value: "technology",
        label: "Technology"
    },
    {
        value: "security",
        label: "Security"
    },
    {
        value: "defense",
        label: "Defense"
    },
    {
        value: "culture",
        label: "Culture"
    },
    {
        value: "sports",
        label: "Sports"
    },
    {
        value: "tourism",
        label: "Tourism"
    },
    {
        value: "transportation",
        label: "Transportation"
    },
    {
        value: "telecommunications",
        label: "Telecommunications"
    },
    {
        value: "media",
        label: "Media"
    },
    {
        value: "law",
        label: "Law"
    },
    {
        value: "justice",
        label: "Justice"
    },
    {
        value: "other",
        label: "Other"
    }
]
const treatyCategories = [
    {
        value: "treaty",
        label: "Treaty"
    },
    {
        value: "convention",
        label: "Convention"
    },
    {
        value: "protocol",
        label: "Protocol"
    },
    {
        value: "declaration",
        label: "Declaration"
    },
    {
        value: "charter",
        label: "Charter"
    },
    {
        value: "ammendment",
        label: "Ammendment"
    },
    {
        value: "annex",
        label: "Annex"
    },
    {
        value: "agreement",
        label: "Agreement"
    }
];
const administrativeBodies = [
    {
        value: 'eastafrica',
        label: 'East Africa'
    },
    {
        value: 'comesa',
        label: 'COMESA'
    },
    {
        value: 'africaunion',
        label: 'African Union'
    },
    {
        value: 'commonwealth',
        label: 'Commonwealth'
    },
    {
        value: 'unitednations',
        label: 'United Nations'
    },
    {
        value: 'others',
        label: 'Others'
    }
];
export const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = (event) => {
            setMatches(event.matches);
        };
        media.addListener(listener);
        return () => media.removeListener(listener);
    }, [matches, query]);

    return matches;
}
// const client = new MeiliSearch({
//     host: 'https://ms-879222cfbb35-5818.fra.meilisearch.io/',
//     apiKey: 'd7ec920e5fd105f057a824667baff5fb904d2c34'
// });

// const _index = client.index('treaty');

function AdvancedSearch() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryText = queryParams.get('query');

    const [adoptation, setAdoptation] = useState(false)
    const [type, setType] = useState(false)
    const [treatyNumber, setTreatyNumber] = useState(false)
    const [treatyCategory, setTreatyCategory] = useState(true)
    const [treatySubject, setTreatySubject] = useState(false)
    const [parties, setParties] = useState(false)
    const [place, setPlace] = useState(false)
    const [region, setRegion] = useState(false)
    const [searchText, setSearchText] = useState(queryText || '')
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)

    const [categories, setCategories] = useState([])
    const [treatyNo, setTreatyNo] = useState('')
    const [subjects, setSubjects] = useState([])
    const [adminBodies, setAdminBodies] = useState([])
    const [selectedCities, setSelectedCities] = useState('')
    const [partiesInvoved, setPartiesInvolved] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [limit, setLimit] = useState(5)
    const [hasMore, setHasMore] = useState(true)
    const [filterString, setFilterString] = useState('')
    const [isTop, setIsTop] = useState(true)
    const [showMenu, setShowMenu] = useState(false)

    const isMd = useMediaQuery('(min-width: 1116px)');

    useEffect(() => {
        setShowMenu(isMd)
    }, [isMd])

    const updateCaterories = (e) => {
        const { name, checked } = e.target
        if (checked) {
            setCategories([...categories, name])
        } else {
            setCategories(categories.filter(item => item !== name))
        }
    }

    const updateSubjects = (e) => {
        const { name, checked } = e.target
        if (checked) {
            setSubjects([...subjects, name])
        } else {
            setSubjects(subjects.filter(item => item !== name))
        }
    }

    const updatePartiesInvolved = (e) => {
        const { name, checked } = e.target
        if (checked) {
            setPartiesInvolved([...partiesInvoved, name])
        } else {
            setPartiesInvolved(partiesInvoved.filter(item => item !== name))
        }
    }

    const updateAdminBodies = (e) => {
        const { name, checked } = e.target
        if (checked) {
            setAdminBodies([...adminBodies, name])
        } else {
            setAdminBodies(adminBodies.filter(item => item !== name))
        }
    }

    const updateCities = (e) => {
        console.log("EVENT:", e)
        const cities = e.map(item => item.value)
        setSelectedCities(cities)
    }

    const [treaties, setTreaties] = useState([])

    useEffect(() => {

        const handleScroll = () => {
            const position = window.pageYOffset;            
            if (position > 300) {
                setIsTop(false)
            } else {
                setIsTop(true)
            }

            console.log("position ---- :", position)
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }

    }, [])

    // useEffect(() => {
    //     if (queryText) {
    //         setSearchText(queryText)
    //     }
    // }, [queryText])

    useEffect(() => {
        handleSearch(searchText)
    }, [searchText])

    useEffect(() => {
        if (treaties.length === limit) {
            setHasMore(true)
        } else {
            setHasMore(false)
        }
    }, [treaties.length, limit])

    useEffect(() => {
        _index.updateFilterableAttributes([
            "docType",
            "partiesInvoved",
            "placeOfAdoption",
            "ratificationDate",
            "region",
            "treatyNo",
            "treatySubject"
        ]).then(() => {
            console.log("SETTINS UPDATED")
        })

    }, [])

    const handleSearch = (text, options = {}) => {
        setLoading(true)
        _index
            .search(text, {
                limit,
                ...options
            })
            .then((response) => {
                setTreaties(response.hits)
                setTotal(response.estimatedTotalHits)
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const resetFilters = () => {
        setCategories([])
        setTreatyNo('')
        setSubjects([])
        setAdminBodies([])
        setSelectedCities('')
        setPartiesInvolved('')
        setStartDate('')
        setEndDate('')
    }

    const applyFilters = async () => {
        setLoading(true)
        let filters = ''

        if (categories.length > 0) {
            filters += `docType = "${categories[0]}"`
            for (let i = 1; i < categories.length; i++) {
                filters += ` OR docType = "${categories[i]}"`
            }
        }

        if (treatyNo.length > 0) {
            if (filters.length > 0) {
                filters += ` AND treatyNo = "${treatyNo}"`
            } else {
                filters += `treatyNo = "${treatyNo}"`
            }
        }

        if (subjects.length > 0) {
            if (filters.length > 0) {
                filters += ` AND treatySubject = "${subjects[0]}"`
            } else {
                filters += `treatySubject = "${subjects[0]}"`
            }

            for (let i = 1; i < subjects.length; i++) {
                filters += ` OR treatySubject = "${subjects[i]}"`
            }
        }

        if (adminBodies.length > 0) {
            if (filters.length > 0) {
                filters += ` AND partiesInvoved = "${adminBodies[0]}"`
            } else {
                filters += `partiesInvoved = "${adminBodies[0]}"`
            }

            for (let i = 1; i < adminBodies.length; i++) {
                filters += ` OR partiesInvoved = "${adminBodies[i]}"`
            }
        }

        if (selectedCities.length > 0) {
            if (filters.length > 0) {
                filters += ` AND placeOfAdoption = "${selectedCities[0]}"`
            } else {
                filters += `placeOfAdoption = "${selectedCities[0]}"`
            }

            for (let i = 1; i < selectedCities.length; i++) {
                filters += ` OR placeOfAdoption = "${selectedCities[i]}"`
            }
        }

        if (partiesInvoved.length > 0) {
            if (filters.length > 0) {
                filters += ` AND partiesInvoved = "${partiesInvoved[0]}"`
            } else {
                filters += `partiesInvoved = "${partiesInvoved[0]}"`
            }

            for (let i = 1; i < partiesInvoved.length; i++) {
                filters += ` OR partiesInvoved = "${partiesInvoved[i]}"`
            }
        }

        if (startDate.length > 0) {
            const _startDate = new Date(startDate).getTime()
            if (filters.length > 0) {
                filters += ` AND ratificationDate > ${_startDate / 1000}`
            } else {
                filters += `ratificationDate > ${_startDate / 1000}`
            }
        }

        if (endDate.length > 0) {
            const _endDate = new Date(endDate).getTime()
            if (filters.length > 0) {
                filters += ` AND ratificationDate < ${_endDate / 1000}`
            } else {
                filters += `ratificationDate < ${_endDate / 1000}`
            }
        }

        setTreaties([])
        setLimit(5)
        setFilterString(filters)

        await handleSearch(searchText, {
            filter: filters,
            limit: 5
        })
    }

    const loadMoreTreaties = () => {
        setLimit(limit + 5)
        handleSearch(searchText, {
            filter: filterString,
            limit: limit + 5
        })
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    console.log("treaties ---- :", treaties)

    return (
        <div>
            <div className='pt-7 md:container mx-auto'>
                <div className='py-5 flex justify-between border-b-2 flex-col md:flex-row items-center md:items-start'>
                    <div className='flex space-x-7 items-center'>
                        <Link to="/">
                            <img src={logo2} alt="logo" className='w-40' />
                        </Link>
                        <img src={court} alt="logo" className='w-16' />
                    </div>
                    <div className='flex space-x-2 items-center mt-4 md:mt-0'>
                        <input
                            placeholder='Search Treaty'
                            className="outline-none h-14 rounded-md text-base px-5 border border-solid border-gray-400"
                            style={{
                                width: "40vw"
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            value={searchText}
                        />
                        <button
                            className="text-white text-base leading-[150%] self-stretch justify-end items-center w-[97px] max-w-full px-5 py-2 rounded-md h-14"
                            style={{
                                background: '#272969',
                                // marginTop: 8
                            }}
                        >Search</button>
                    </div>
                    <div />
                </div>
            </div>

            {/*  */}
            {searchText.length > 0 &&
                <section className="container mx-auto text-2xl text-center mt-4 pb-4 border-b-2">
                    <span className="text-neutral-400">{total} Search Results for</span>
                    <span className="text-neutral-800"> {searchText}</span>
                </section>
            }

            <section className="sm:container mx-auto max-md:w-full gap-5 flex max-md:flex-col max-md:gap-0">
            {/* <section className="container mx-auto max-md:w-full gap-5 flex"> */}
                {/* Add floating filter button */}
                {!showMenu && <button className="fixed top-26 left-5 z-50 bg-white rounded-full shadow-md p-3.5 llg:hidden flex items-center gap-2" onClick={() => setShowMenu(!showMenu)}>
                    Search Filter
                    <FaFilter/>
                </button>}
                {showMenu && <article className="items-stretch w-[22%] max-md:w-full max-md:ml-0 fixed top-10 z-20 llg:static">
                    <div className="flex flex-col w-[295px] bg-white mt-5 border rounded llg:border-0 px-5 pb-5">
                        <div className="items-start content-start flex-wrap bg-white self-stretch flex w-full flex-col px-3.5 py-3 max-md:mr-px">
                            <div className="flex w-full items-center gap-2.5">
                                <h2 className="text-neutral-800 text-base font-semibold leading-[150%] self-stretch w-full">Applied Filters</h2>
                                <AiFillCloseSquare size={28} color={'grey'} onClick={() => !isMd && setShowMenu(false)}/>
                            </div>
                            {/* <div className="items-start content-start flex-wrap flex w-[186px] max-w-full gap-2.5 mt-5">
                                <a href="#" className="text-white text-xs font-light leading-[150%] self-stretch justify-center items-center bg-red-600 flex-1 px-3.5 py-2 rounded-xl">Bi-lateral</a>
                                <a href="#" className="text-neutral-400 text-xs font-light leading-[150%] self-stretch justify-center items-center border border-[color:var(--Grey-Mid,#999)] flex-1 px-3.5 py-2 rounded-xl border-solid">Multilateral</a>
                            </div> */}
                        </div>
                        {/* <button
                            className="text-slate-500 underline text-right text-sm"
                            onClick={resetFilters}
                        >Reset filters</button> */}

                        <div className="items-start self-stretch flex grow flex-col w-full mt-2 max-h-[620px] llg:max-h-full llg:h-auto overflow-auto">

                            <div className="content-center flex-wrap bg-white self-stretch flex w-full flex-col px-3.5 py-2.5">
                                <div
                                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                                    onClick={() => setTreatyCategory(!treatyCategory)}
                                >
                                    <div className="flex items-center gap-3.5">
                                        {/* <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" /> */}
                                        <MdBalance size={24} className="text-slate-500" />
                                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Treaty Category</div>
                                    </div>
                                    {treatyCategory ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                                </div>
                                {treatyCategory &&
                                    <div className="flex flex-col gap-2.5 mt-2 ml-14">
                                        {
                                            treatyCategories.map((item, index) => {
                                                return (
                                                    <div className="flex items-center gap-1.5">
                                                        <input type="checkbox" name={item.value} id={item.value} onChange={updateCaterories} />
                                                        <label htmlFor={item.value} className="text-slate-500 text-sm font-medium leading-[142.857%]">{item.label}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>

                            <div className="items-center content-center flex-wrap bg-white self-stretch flex w-full flex-col px-3.5 py-2.5">
                                <div
                                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                                    onClick={() => setTreatyNumber(!treatyNumber)}
                                >
                                    <div className="flex items-center gap-3.5">
                                        {/* <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" /> */}
                                        <FiFileText size={24} className="text-slate-500" />
                                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Treaty Number </div>
                                    </div>
                                    {treatyNumber ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                                </div>
                                {treatyNumber &&
                                    <div className="w-full mt-2 ml-14">
                                        <input
                                            type="text" placeholder="Enter Treaty Number"
                                            className="outline-none text-sm leading-[142.857%] items-center bg-white self-stretch mr-0 grow basis-auto px-3.5 py-2 rounded-md max-w-full border"
                                            onChange={
                                                (e) => setTreatyNo(e.target.value)
                                            }
                                        />
                                    </div>
                                }
                            </div>

                            <div className="content-center flex-wrap bg-white self-stretch flex w-full flex-col px-3.5 py-2.5">
                                <div
                                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                                    onClick={() => setTreatySubject(!treatySubject)}
                                >
                                    <div className="flex items-center gap-3.5">
                                        {/* <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" /> */}
                                        <FiFileText size={24} className="text-slate-500" />
                                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Treaty Subject </div>
                                    </div>
                                    {treatySubject ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                                </div>
                                {treatySubject &&

                                    <div className="flex flex-col gap-2.5 mt-2 ml-14">
                                        {
                                            treatySubjects.map((item, index) => {
                                                return (
                                                    <div className="flex items-center gap-1.5">
                                                        <input type="checkbox" name={item.value} id={item.value} onChange={updateSubjects} />
                                                        <label htmlFor={item.value} className="text-slate-500 text-sm font-medium leading-[142.857%]">{item.label}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>

                            {/* <div className="items-center content-center flex-wrap bg-white self-stretch flex w-full flex-col px-3.5 py-2.5">
                                <div
                                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                                    onClick={() => setType(!type)}
                                >
                                    <div className="flex items-center gap-3.5">
                                        <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" />
                                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Treaty Type </div>
                                    </div>
                                    {type ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                                </div>
                                {type &&
                                    <div className="items-start flex w-full gap-5 mt-3.5">

                                    </div>
                                }
                            </div> */}

                            <div className="content-center flex-wrap bg-white self-stretch flex w-full flex-col px-3.5 py-2.5">
                                <div
                                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                                    onClick={() => setParties(!parties)}
                                >
                                    <div className="flex items-center gap-3.5">
                                        {/* <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/bc637650-c596-473f-aa1b-6756eaa403ea?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bc637650-c596-473f-aa1b-6756eaa403ea?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bc637650-c596-473f-aa1b-6756eaa403ea?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bc637650-c596-473f-aa1b-6756eaa403ea?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bc637650-c596-473f-aa1b-6756eaa403ea?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bc637650-c596-473f-aa1b-6756eaa403ea?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bc637650-c596-473f-aa1b-6756eaa403ea?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/bc637650-c596-473f-aa1b-6756eaa403ea?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" /> */}
                                        <PiUsersThree size={24} className="text-slate-500" />
                                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Parties Involved</div>
                                    </div>
                                    {parties ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                                </div>
                                {parties &&
                                    <div className="flex flex-col gap-2.5 mt-2 ml-14">
                                        <div className="flex items-center gap-1.5">
                                            <input type="checkbox" id="Bilateral" name={'bilateral'} onChange={updatePartiesInvolved} />
                                            <label className="text-slate-500 text-sm font-medium leading-[142.857%] my-auto " htmlFor="Bilateral">Bilateral</label>
                                        </div>
                                        <div className="flex items-center gap-1.5">
                                            <input type="checkbox" id="Multilateral" name={'multilateral'} onChange={updatePartiesInvolved} />
                                            <label className="text-slate-500 text-sm font-medium leading-[142.857%] my-auto" htmlFor="Multilateral">Multilateral</label>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="items-center content-center flex-wrap bg-white self-stretch flex w-full flex-col px-3.5 py-2.5">
                                <div
                                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                                    onClick={() => setAdoptation(!adoptation)}
                                >
                                    <div className="flex items-center gap-3.5">
                                        <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9582ff9d-d194-4c83-ba06-25b60c59a360?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9582ff9d-d194-4c83-ba06-25b60c59a360?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9582ff9d-d194-4c83-ba06-25b60c59a360?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9582ff9d-d194-4c83-ba06-25b60c59a360?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9582ff9d-d194-4c83-ba06-25b60c59a360?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9582ff9d-d194-4c83-ba06-25b60c59a360?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9582ff9d-d194-4c83-ba06-25b60c59a360?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9582ff9d-d194-4c83-ba06-25b60c59a360?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" />
                                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Ractification Date</div>
                                    </div>
                                    {adoptation ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                                </div>
                                {adoptation && <div className="items-start flex w-full gap-5 mt-3.5">
                                    <div className="items-start flex flex-col flex-1">
                                        <div className="text-neutral-700 text-xs font-light leading-[150%] max-md:mr-px">Start Date</div>
                                        {/* <div className="text-neutral-400 text-xs font-light leading-[150%] items-center border border-[color:var(--lighter-grey-strokes,#ECEDED)] bg-white self-stretch w-full grow mt-2 pl-2.5 py-2 rounded-xl border-solid">09/08/2023</div> */}
                                        <input
                                            type="date"
                                            className="text-neutral-400 text-xs font-light leading-[150%] items-center border border-[color:var(--lighter-grey-strokes,#ECEDED)] bg-white self-stretch w-full grow mt-2 pl-2.5 py-2 rounded-xl border-solid"
                                            onChange={
                                                (e) => setStartDate(e.target.value)
                                            }
                                        />

                                    </div>
                                    <div className="items-start flex flex-col flex-1">
                                        <div className="text-neutral-700 text-xs font-light leading-[150%] max-md:mr-px">End Date</div>
                                        {/* <div className="text-neutral-400 text-xs font-light leading-[150%] items-center border border-[color:var(--lighter-grey-strokes,#ECEDED)] bg-white self-stretch w-full grow mt-2 pl-2.5 py-2 rounded-xl border-solid">09/08/2023</div> */}
                                        <input
                                            type="date"
                                            className="text-neutral-400 text-xs font-light leading-[150%] items-center border border-[color:var(--lighter-grey-strokes,#ECEDED)] bg-white self-stretch w-full grow mt-2 pl-2.5 py-2 rounded-xl border-solid"
                                            onChange={
                                                (e) => setEndDate(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>}
                            </div>

                        {/* </div> */}

                        <div className="items-start bg-white self-stretch flex flex-col w-full gap-3.5 px-3.5 py-3 max-md:justify-center">
                            <div
                                className="flex w-full justify-between max-md:justify-center cursor-pointer"
                                onClick={() => setPlace(!place)}
                            >
                                <div className="flex items-center gap-3.5">
                                    {/* <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" /> */}
                                    {/* <GrLocation size={24} className="text-slate-500" /> */}
                                    <SlLocationPin size={24} className="text-slate-500" />
                                    <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Place of Adoption</div>
                                </div>
                                {place ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                            </div>
                            {place &&
                                <div className="items-start flex w-[90%] gap-5 ml-6">
                                    <Select options={cities}
                                        className='w-full'
                                        isMulti
                                        onChange={updateCities}
                                    />
                                </div>
                            }
                        </div>

                        <div className="items-start bg-white self-stretch flex flex-col w-full gap-3.5 px-3.5 py-3 max-md:justify-center">
                            <div
                                className="flex w-full justify-between max-md:justify-center cursor-pointer"
                                onClick={() => setRegion(!region)}
                            >
                                <div className="flex items-center gap-3.5">
                                    {/* <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/caba789a-77b9-402b-bc25-7cd4f7789eb4?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" /> */}
                                    <PiUsersThree size={24} className="text-slate-500" />
                                    <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Administrative Body</div>
                                </div>
                                {region ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                            </div>
                            {region &&
                                <div className="flex flex-col gap-2.5 ml-14">
                                    {
                                        administrativeBodies.map((item, index) => {
                                            return (
                                                <div className="flex items-center gap-1.5">
                                                    <input type="checkbox" name={item.value} id={item.value} onChange={updateAdminBodies} />
                                                    <label htmlFor={item.value} className="text-slate-500 text-sm font-medium leading-[142.857%]">{item.label}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            </div>

                            {/* Add two buttons one to reset and annother too apply the filters  */}
                            {/* <div className="flex items-center justify-between mt-5"> */}
                            {/* <button className="" onClick={resetFilters}>Reset</button> */}
                            <button className="bg-sky-500 text-white text-sm font-medium px-3.5 py-2 rounded-md w-full mt-4 hover:bg-sky-600 " onClick={applyFilters}>Apply Filters</button>
                            {/* </div> */}
                        </div>

                    </div>
                </article>}
                <article className="w-[100%] llg:w-[76%] md:ml-5">
                    <div className="bg-slate-50 flex flex-col w-full h-full mx-auto pt-8 pb-28 px-5 gap-y-8 max-md:max-w-full">
                        {
                            treaties.map((item, index) => {
                                return (
                                    <Link to={`/treaties/${item.id}`}>
                                        <div className="items-start flex mb-0 w-[864px] max-w-full flex-col md:ml-3 max-md:mb-2.5 cursor-pointer">
                                            <div className="self-stretch max-md:max-w-full">
                                                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                                    <div className="flex flex-col items-stretch w-[24%] max-md:w-full max-md:ml-0">
                                                        <img
                                                            loading="lazy"
                                                            src={TreatyImg}
                                                            // srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/07baeab5-7dd0-421b-8d18-02eb556c0805?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/07baeab5-7dd0-421b-8d18-02eb556c0805?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/07baeab5-7dd0-421b-8d18-02eb556c0805?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/07baeab5-7dd0-421b-8d18-02eb556c0805?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/07baeab5-7dd0-421b-8d18-02eb556c0805?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/07baeab5-7dd0-421b-8d18-02eb556c0805?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/07baeab5-7dd0-421b-8d18-02eb556c0805?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/07baeab5-7dd0-421b-8d18-02eb556c0805?apiKey=345ac80390ed4496b041ffff961f4164&"
                                                            className="aspect-[1.3] object-cover object-center w-full overflow-hidden grow max-md:mt-6 rounded-lg"
                                                        />
                                                    </div>
                                                    <div className="flex flex-col items-stretch w-[76%] ml-5 max-md:w-full max-md:ml-2">
                                                        <div className="items-start flex flex-col max-md:max-w-full max-md:mt-3">
                                                            <div className="text-neutral-800 text-xl font-light leading-[150%] mt-2.5 max-md:max-w-full">
                                                                {item.treatyTitle || "N/A"}
                                                            </div>
                                                            <div className="justify-center text-violet-950 text-xs font-light leading-5 mt-2.5">
                                                                <span className="text-neutral-400">Ractification Date</span>
                                                                <span className="text-violet-950">: {item.ratificationDate || "N/A"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                        {
                            !loading && treaties.length === 0 &&
                            <div className="text-neutral-800 text-xl font-light leading-[150%] mt-2.5 max-md:max-w-full">
                                No treaties found
                            </div>
                        }
                        {
                            loading &&
                            <div className="text-neutral-800 text-xl font-light leading-[150%] mt-2.5 max-md:max-w-full text-center">
                                Loading Treaties...
                            </div>
                        }
                        {/* Add pagination buttons */}
                        <div className="flex justify-center mt-5">
                            {hasMore && <button className="bg-sky-500 text-white text-sm font-medium px-3.5 py-2 rounded-md w-40 text-center hover:bg-sky-600 " onClick={loadMoreTreaties}>Load More</button>}
                        </div>
                    </div>
                </article>
            </section>

            <footer className="bg-bgBlue self-stretch relative flex flex-col mt-8 pt-14 pb-20 px-5 max-md:max-w-full max-md:mt-10">
                <div className="md:container mx-auto">
                    <hr className="bg-zinc-400 bg-opacity-60 self-center h-[1px] max-md:max-w-full" />
                    <div className="self-center md:flex w-full  items-start md:justify-between gap-5 mt-7">
                        <div className="text-white text-base opacity-80">
                            Copyright &copy; 2023 Uganda Law Reform Commission.
                        </div>
                        <div className="flex gap-3 md:gap-5 mt-7 md:mt-0 flex-col md:flex-row text-center">
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Partners
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                About Treaty DB
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Glossary
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Blog
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Terms of Use
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Add a scroll to top button  */}
            <div className="fixed bottom-32 right-5">
                {!isTop && <button className="bg-sky-500 text-white text-sm font-medium px-3.5 py-2 rounded-md w-full hover:bg-sky-600 " onClick={scrollToTop}>
                    Back to Top
                </button>}
            </div>

        </div>
    )
}

export default AdvancedSearch
