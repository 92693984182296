import React from 'react'

function DasboardContent() {
    return (
        <div className="flex flex-col items-stretch mt-14 px-5 max-md:max-w-full max-md:mt-10">
            <h1 className="text-neutral-800 text-lg font-semibold leading-8 -mr-5 max-md:max-w-full">
                Dashboard
            </h1>
        </div>
    )
}

export default DasboardContent
