import * as React from "react";
import { RxDashboard } from "react-icons/rx";
import { PiFiles } from "react-icons/pi";
import { IoLogOutOutline } from "react-icons/io5";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import logo2 from '../ulrc_logo.png';
import logo from '../ulrc_logo1.png';

function TreatyDashboard(props) {

    const navigate = useNavigate();

    React.useEffect(() => {
        const user = localStorage.getItem("user")
        if (!user) {
            navigate('/')
        }
    }, [])

    const logout = () => {
        // localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
    }

    return (
        <main className="bg-white">
            <section className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                <aside className="flex flex-col items-stretch w-[21%] max-md:w-full max-md:ml-0">
                    <header className="bg-bgBlue flex w-full grow flex-col mx-auto px-7 py-12 max-md:mt-8 max-md:px-5">
                        <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-[3.11] object-contain object-center w-[137px] overflow-hidden max-w-full ml-5 self-start max-md:ml-2.5"
                            alt="Logo"
                        />                        
                        <NavLink
                            className={({ isActive }) => {
                                return `items-center ${isActive ? 'bg-red-600' : 'opacity-50'} text-white self-stretch flex justify-between gap-4 mt-10 pl-5 pr-20 py-3.5 rounded-xl max-md:pr-5 cursor-pointer`;
                            }}
                            to="/dashboard/info"
                        >
                            <RxDashboard size={18} className={"text-white"} />
                            <div className=" text-base leading-6 self-stretch grow whitespace-nowrap">
                                Dashboard
                            </div>
                        </NavLink>
                        <NavLink
                            className={({ isActive }) => {
                                return `items-center ${isActive ? 'bg-red-600' : 'opacity-50'} text-white self-stretch flex justify-between gap-4 mt-1.5 pl-5 pr-20 py-3.5 rounded-xl max-md:pr-5 cursor-pointer`;
                            }}
                            to="/dashboard/treaties"
                        >
                            <PiFiles size={20} />
                            <div className="text-base leading-6 self-stretch grow whitespace-nowrap">
                                Treaties
                            </div>
                        </NavLink>
                        <nav
                            className="items-center opacity-50 bg-bgBlue self-stretch flex justify-between gap-4 mt-1.5 mb-[503px] pl-5 pr-20 py-3.5 rounded-xl max-md:mb-10 max-md:pr-5 cursor-pointer"
                            onClick={logout}
                        >
                            <IoLogOutOutline size={20} className={"text-white"} />
                            <div className="text-white text-base leading-6 self-stretch grow whitespace-nowrap">
                                Logout
                            </div>
                        </nav>
                        
                    </header>
                </aside>
                <section className="flex flex-col items-stretch w-[75%] ml-5 max-md:w-full max-md:ml-0">
                    <Outlet />
                </section>
            </section>
            <footer className="bg-bgBlue self-stretch relative flex flex-col pt-14 pb-20 px-5 max-md:max-w-full max-md:mt-10 border-t">
                <div className="md:container mx-auto">
                    <hr className="bg-zinc-400 bg-opacity-60 self-center h-[1px] max-md:max-w-full" />
                    <div className="self-center md:flex w-full  items-start md:justify-between gap-5 mt-7">
                        <div className="text-white text-base opacity-80">
                            Copyright &copy; 2023 Uganda Law Reform Commission.
                        </div>
                        <div className="flex gap-3 md:gap-5 mt-7 md:mt-0 flex-col md:flex-row text-center">
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Partners
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                About Treaty DB
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Glossary
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Blog
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Terms of Use
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </main>
    );
}

export default TreatyDashboard;