import { useEffect, useRef } from "react";
import WebViewer from '@pdftron/pdfjs-express-viewer';

const PdfPreview = ({ closePreview, preview, docUrl }) => {
    const viewerRef = useRef(null);

    useEffect(() => {
        if (viewerRef.current) {
            WebViewer(
                {
                    path: '/',
                    // initialDoc: 'https://ulrc-treaties.s3.amazonaws.com/ICCPR.pdf',
                    initialDoc: docUrl,
                    licenseKey: 'XzoxB8gzLv9SGYSB2eKr'
                },
                viewerRef.current,
            )
        }
    }, [viewerRef]);
      
    return (
        <div
            className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center"
            style={{
                background: 'rgba(0,0,0,0.7)',
                visibility: preview ? 'visible' : 'hidden'
            }}
        >
            <div
                className="absolute top-0 right-0 m-5 cursor-pointer"
                onClick={closePreview}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#fff"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </div>
            <div
                className="relative bg-white w-[80vw] h-[95vh] max-w-[1191px] border border-solid border-white"
                style={{
                    background: '#272969',
                }}
            >
                <div
                    className="relative w-full h-full"
                    ref={viewerRef}
                ></div>
            </div>

        </div>
    );
};

export default PdfPreview;
