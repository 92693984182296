import React from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom'

function Treaties() {
    return (
        <div className="flex flex-col items-stretch mt-14 px-5 max-md:max-w-full max-md:mt-10">
            <h1 className="text-neutral-800 text-lg font-semibold leading-8 -mr-5 max-md:max-w-full">
                Treaties
            </h1>
            <div className="items-stretch flex w-full justify-between gap-5 mt-5 self-start max-md:flex-wrap mb-4 border-slate-100 relative">
                <div className='w-full absolute border-b-2 bottom-0'/>
                <NavLink
                    className={({ isActive }) => {
                        return `text-base leading-6 flex-1 text-center pb-2 ${isActive ? "text-violet-950 border-b-2 border-violet-950 z-20" : "text-neutral-400"}`
                    }}
                    to="/dashboard/treaties/search"
                >
                    Search Treaty
                </NavLink>
                <NavLink                
                    className={({ isActive }) => {
                        return `text-base leading-6 flex-1 text-center pb-2 ${isActive ? "text-violet-950 border-b-2 border-violet-950 z-20" : "text-neutral-400"}`
                    }}
                    to="/dashboard/treaties/upload"
                >
                    Upload Treaty
                </NavLink>
            </div>
            <Outlet />
        </div>
    )
}

export default Treaties
