import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import AWS from 'aws-sdk';
import { s3Config } from '../configs';
import { cities } from './AdvancedSearch';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const docTypeOptions = [
    { value: 'convention', label: 'Convention' },
    { value: 'protocol', label: 'Protocol' },
    { value: 'declaration', label: 'Declaration' },
    { value: 'charter', label: 'Charter' },
    { value: 'amendment', label: 'Amendment' },
    { value: 'annex', label: 'Annex' },
];

const treatySubjectOptions = [
    { value: 'education', label: 'Education' },
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'security', label: 'Security' },
    { value: 'tourism', label: 'Tourism' },
    { value: 'trade', label: 'Trade' },
    { value: 'environment', label: 'Environment' },
    { value: 'children', label: 'Children' },
    { value: 'relations', label: 'Relations' },
    { value: 'energy', label: 'Energy' },
    { value: 'drugs', label: 'Drugs' },
    { value: 'aviation', label: 'Aviation' },
    { value: 'human_rights', label: 'Human Rights' },
    { value: 'labour', label: 'Labour' },
  ];
  

const regionOptions = [
    { value: 'eastafrica', label: 'East Africa' },
    { value: 'comesa', label: 'COMESA' },
    { value: 'africa', label: 'Africa' },
    { value: 'commonwealth', label: 'Commonwealth' },
    { value: 'unitednations', label: 'United Nations' },
    { value: 'others', label: 'Others' },
];

const partyOptions = [
    { value: 'bilateral', label: 'Bilateral' },
    { value: 'multilateral', label: 'Multilateral' },
]

const AdminUpload = () => {
    const [treatyNo, setTreatyNo] = useState('');
    const [docType, setDocType] = useState('');
    const [dateOfAssention, setDateOfAssention] = useState('');
    const [description, setDescription] = useState('');
    const [treatySubject, setTreatySubject] = useState('');
    const [region, setRegion] = useState('');
    const [placeOfAdoption, setPlaceOfAdoption] = useState('');
    const [ratificationDate, setRatificationDate] = useState('');
    const [adoptionDate, setAdoptionDate] = useState('');
    const [signatureDate, setSignatureDate] = useState('');
    const [domesticLegislations, setDomesticLegislations] = useState([]);
    const [institutionalReforms, setInstitutionalReforms] = useState([]);
    const [reformOptions, setReformOptions] = useState([]);
    const [reportOptions, setReportOptions] = useState([]);
    const [legislationOptions, setLegislationOptions] = useState([]);
    const [document, setDocument] = useState(null);
    const [partiesInvolved, setPartiesInvolved] = useState('');
    const [treatyTitle, setTreatyTitle] = useState('');
    const [depositDate, setDepositDate] = useState('');
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(false)
    const fileInputRef = useRef(null);

    const navigate = useNavigate()

    const handleUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = (e) => {
        setDocument(e.target.files[0]);
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const [reportsResponse, domesticLegislationsResponse, institutionalReformsResponse] = await Promise.all([
              axios.get('https://treaties.laws.go.ug/api/reports'),
              axios.get('https://treaties.laws.go.ug/api/domestic-legislations'),
              axios.get('https://treaties.laws.go.ug/api/institutional-reforms'),
            ]);            
    
            setReportOptions(
              reportsResponse.data?.data?.map((report) => ({ value: report.id, label: report?.attributes?.UgandaStatusReports }))
            );
            setLegislationOptions(
              domesticLegislationsResponse.data?.data?.map((legislation) => ({ value: legislation.id, label: legislation?.attributes?.legislation }))
            );
            setReformOptions(
              institutionalReformsResponse.data?.data?.map((reform) => ({ value: reform.id, label: reform?.attributes?.reforms }))
            );
          } catch (error) {
            console.error('Error fetching data', error);
          }
        };
    
        fetchData();
    }, []);

    const handleReload = () => {
        navigate(0);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const treatyData = {
            treatyNo,
            docType,
            dateOfAssention,
            description,
            treatySubject,
            region,
            placeOfAdoption,
            ratificationDate,
            adoptionDate,
            signatureDate,
            domestic_legislations: domesticLegislations.map(legislation => legislation.value),
            institutional_reforms: institutionalReforms.map(reform => reform.value),
            partiesInvolved,
            treatyTitle,
            depositDate,
            reports: reports.map(report => report.value),
        };

        const filteredTreatyData = Object.fromEntries(
            Object.entries(treatyData).filter(([_, value]) => value)
        );

        setLoading(true)

        if (document) {
            const fileUrl = await uploadFileToS3(document);
            filteredTreatyData.docURL = fileUrl;
        }

        try {
            const response = await axios.post('https://treaties.laws.go.ug/api/treaties', { data: { ...filteredTreatyData } });
            if(response){
                toast.success("Treaty has been added successful");
                setTimeout(() => {
                    handleReload()
                }, 5000)
            }
        } catch (error) {
            console.error(error);
            toast.error("Error: Treaty addition failed!");
        } finally {
            setLoading(false)
        }
    };

    const uploadFileToS3 = async (file) => {
        try {
            const fileUploadResponse = await s3Config.upload({
                Bucket: 'ulrc-treaties',
                Key: file.name,
                Body: file,
                ContentType: file.type,
            }).promise();
            return fileUploadResponse.Location;
        } catch (error) {
            console.error(error);
        }
    };
    
    
    return (
        <div className="bg-slate-100 flex flex-col pl-8 pr-14 rounded-2xl max-md:px-5 pb-10 mb-10">
            <h4 className="text-violet-950 text-2xl leading-[54px] whitespace-nowrap mt-4 self-start max-md:text-4xl max-md:leading-[50px] max-md:ml-2.5 max-md:mt-10">
                Upload Treaty
            </h4>

            <div className="mt-5">
                <label className="text-neutral-800 text-base leading-6 tracking-wide">Treaty Number</label>
                <input
                    className="w-full bg-white mt-2 pl-3.5 pr-4 py-2 rounded-xl"
                    placeholder="Input the treaty number"
                    value={treatyNo}
                    onChange={(e) => setTreatyNo(e.target.value)}
                />
            </div>

            <div className="mt-5">
                <label className="text-neutral-800 text-base leading-6 tracking-wide">Treaty Title</label>
                <input
                    className="w-full bg-white mt-2 pl-3.5 pr-4 py-2 rounded-xl"
                    placeholder="Input the treaty title"
                    value={treatyTitle}
                    onChange={(e) => setTreatyTitle(e.target.value)}
                />
            </div>

            <div className="mt-5">
                <label className="text-neutral-800 text-base leading-6 tracking-wide">Parties Involved</label>
                {/* <input
          className="w-full bg-white mt-2 pl-3.5 pr-4 py-2 rounded-xl"
          placeholder="Input the parties involved"
          value={partiesInvolved}
          onChange={(e) => setPartiesInvolved(e.target.value)}
        /> */}
                <Select
                    options={partyOptions}
                    className="pt-2"
                    placeholder="Select the parties involved"
                    // value={partiesInvolved}
                    onChange={(e) => setPartiesInvolved(e.value)}
                />
            </div>

            <div className="flex gap-5 mt-5">
                <div className="flex flex-col w-1/2">
                    <label className="text-neutral-800 text-base leading-6 tracking-wide">Date of Assention</label>
                    <input
                        className="w-full bg-white mt-2 pl-3.5 pr-3 py-2 rounded-xl"
                        type="date"
                        value={dateOfAssention}
                        onChange={(e) => setDateOfAssention(e.target.value)}
                    />
                </div>
                <div className="flex flex-col w-1/2">
                    <label className="text-neutral-800 text-base leading-6 tracking-wide">Document Type</label>
                    <Select
                        options={docTypeOptions}
                        className="pt-2"
                        placeholder="Choose here"
                        // value={docType}
                        onChange={(selectedOption) => setDocType(selectedOption.value)}
                    />
                </div>
            </div>

            <div className="flex gap-5 mt-5">
                <div className="flex flex-col w-1/2">
                    <label className="text-neutral-800 text-base leading-6 tracking-wide">Treaty Subject</label>
                    <Select
                        className="pt-2"
                        placeholder="Choose here"
                        options={treatySubjectOptions}
                        // value={treatySubject}
                        onChange={(selectedOption) => setTreatySubject(selectedOption.value)}
                    />
                </div>
                <div className="flex flex-col w-1/2">
                    <label className="text-neutral-800 text-base leading-6 tracking-wide">Region</label>
                    <Select
                        className="pt-2"
                        placeholder="Choose here"
                        options={regionOptions}
                        // value={region}
                        onChange={(selectedOption) => setRegion(selectedOption.value)}
                    />
                </div>
            </div>

            <div className="mt-5">
                <label className="text-neutral-800 text-base leading-6 tracking-wide">Description</label>
                <textarea
                    className="w-full bg-white mt-2 pt-4 pb-24 px-3.5 rounded-xl"
                    placeholder="Add Treaty description here"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>

            <div className="flex gap-5 mt-5">
                <div className="flex flex-col w-1/2">
                    <label className="text-neutral-800 text-base leading-6 tracking-wide">Place of Adoption</label>
                    <Select
                        className="pt-2"
                        placeholder="Choose here"
                        options={cities}
                        // value={placeOfAdoption}
                        onChange={(selectedOption) => setPlaceOfAdoption(selectedOption.value)}
                    />
                </div>
                <div className="flex flex-col w-1/2">
                    <label className="text-neutral-800 text-base leading-6 tracking-wide">Signature Date</label>
                    <input
                        className="w-full bg-white mt-2 pl-3.5 pr-3 py-2 rounded-xl"
                        type="date"
                        value={signatureDate}
                        onChange={(e) => setSignatureDate(e.target.value)}
                    />
                </div>
            </div>

            <div className="flex gap-5 mt-5">
                <div className="flex flex-col w-1/2">
                    <label className="text-neutral-800 text-base leading-6 tracking-wide">Ratification Date</label>
                    <input
                        className="w-full bg-white mt-2 pl-3.5 pr-3 py-2 rounded-xl"
                        type="date"
                        value={ratificationDate}
                        onChange={(e) => setRatificationDate(e.target.value)}
                    />
                </div>
                <div className="flex flex-col w-1/2">
                    <label className="text-neutral-800 text-base leading-6 tracking-wide">Adoption Date</label>
                    <input
                        className="w-full bg-white mt-2 pl-3.5 pr-3 py-2 rounded-xl"
                        type="date"
                        value={adoptionDate}
                        onChange={(e) => setAdoptionDate(e.target.value)}
                    />
                </div>
            </div>

            <div className="mt-5">
                <label className="text-neutral-800 text-base leading-6 tracking-wide">Deposit Date</label>
                <input
                    className="w-full bg-white mt-2 pl-3.5 pr-4 py-2 rounded-xl"
                    type="date"
                    value={depositDate}
                    onChange={(e) => setDepositDate(e.target.value)}
                />
            </div>

            <div className="mt-5">
                <label className="text-neutral-800 text-base leading-6 tracking-wide">Reports</label>
                {/* <textarea
                    className="w-full bg-white mt-2 pt-4 pb-24 px-3.5 rounded-xl"
                    placeholder="Add reports here"
                    value={reports}
                    onChange={(e) => setReports(e.target.value)}
                /> */}
                <Select
                    className="pt-2"
                    placeholder="Select Reports"
                    isMulti   
                    options={reportOptions}                 
                    onChange={(selectedOptions) => setReports(selectedOptions)}
                />
            </div>

            <div className="mt-5">
                <label className="text-neutral-800 text-base leading-6 tracking-wide">Document</label>
                <div
                    className={`bg-white flex flex-col mt-2 rounded-xl cursor-pointer ${document ? 'p-5' : 'p-5'}`}
                    onClick={handleUpload}
                >
                    {document ? (
                        <div className="flex items-center">
                            <span>{document.name}</span>
                        </div>
                    ) : (
                        <>
                            <img
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e95df6aa-edf8-4817-9bbc-2ebf2e751221?apiKey=345ac80390ed4496b041ffff961f4164&"
                                className="aspect-[1.13] object-contain object-center w-[79px] overflow-hidden mt-5"
                            />
                            <div className="flex justify-between mt-2.5 mb-5">
                                <span className="text-neutral-400 text-base">Click to add a file</span>
                                <img
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/ec52c960-a2fb-4a5f-b556-0692f10e2fa8?apiKey=345ac80390ed4496b041ffff961f4164&"
                                    className="aspect-[0.06] object-contain w-px self-center"
                                />
                            </div>
                        </>
                    )}
                </div>
                <input
                    type="file"
                    className="hidden"
                    ref={fileInputRef}
                    onChange={handleFileInputChange}
                />
            </div>

            <div className="flex gap-5 mt-5">
                <div className="flex flex-col w-1/2">
                    <label className="text-neutral-800 text-base leading-6 tracking-wide">Domestic Legislations</label>
                    <Select
                        className="pt-2"
                        placeholder="Choose Legislations"
                        isMulti
                        options={legislationOptions}
                        // value={domesticLegislations}
                        onChange={(selectedOptions) => setDomesticLegislations(selectedOptions)}
                    />
                </div>
                <div className="flex flex-col w-1/2">
                    <label className="text-neutral-800 text-base leading-6 tracking-wide">Institutional Reforms</label>
                    <Select
                        className="pt-2"
                        placeholder="Choose Reforms"
                        isMulti
                        options={reformOptions}
                        // value={institutionalReforms}
                        onChange={(selectedOptions) => setInstitutionalReforms(selectedOptions)}
                    />
                </div>
            </div>

            <div className="flex justify-end mt-5">
                <button
                    onClick={handleSubmit}
                    disabled={loading}
                    className="text-white bg-brightRed px-5 py-2 rounded-2xl"
                >
                    {loading ? "Please Wait..." : "Save"}
                </button>
            </div>
        </div>
    );
};

export default AdminUpload;
