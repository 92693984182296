import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import logo2 from '../ulrc_logo.png';
import court from '../image_ug.png';
import { toast } from "react-toastify";
import axios from "axios";

const initialUserData = {
    email: "",
    password: ""
}

function LoginForm(props) {

    const [user, setUser] = React.useState(initialUserData)
    const [isPasswordVisible, setPasswordVisible] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const navigate = useNavigate();


    const validation = () => {
        for (const property in user) {
            if (user[property].length === 0) {
                toast.error(`${property} field cannot be empty`, {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                return false;
            }
        }

        return true
    }

    const login = async () => {
        const url = `https://treaties.laws.go.ug/api/auth/local`
        const loginData = { identifier: user.email, password: user.password }

        try {
            setLoading(true)
            const response = await axios.post(url, loginData)
            localStorage.setItem("user", JSON.stringify(response.data))
            navigate('/dashboard/treaties', { replace: true })
        } catch (error) {
            const errorMsg = error?.response?.data?.error?.message || error.message
            toast.error(errorMsg, {
                position: toast.POSITION.BOTTOM_CENTER
            })
        } finally {
            setLoading(false)
        }
    }   

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validation()) return;
        await login()
    }


    return (
        <div className="h-full flex flex-col justify-between">
            <main className="bg-white flex flex-col items-center px-5 mb-40">
                <div className='pt-7 container mx-auto'>
                    <div className='py-5 flex items-center justify-between'>
                        <div className='flex space-x-7 items-center'>
                            <Link to="/">
                                <img src={logo2} alt="logo" className='w-40' />
                            </Link>
                            <img src={court} alt="logo" className='w-16' />
                        </div>
                        <div>
                            <button
                                className="text-white text-base leading-[150%] self-stretch justify-end items-center w-[97px] max-w-full px-5 py-2 rounded-md"
                                onClick={() => navigate('/')}
                                style={{
                                    background: '#272969',
                                }}
                            >Back</button>
                        </div>
                    </div>
                </div>
                <section className="flex w-[413px] max-w-full flex-col mt-10  max-md:my-10">
                    <h1 className="text-neutral-800 text-5xl leading-[54px] self-center whitespace-nowrap max-md:text-4xl max-md:leading-[50px]">
                        Welcome Back
                    </h1>
                    <label className="text-neutral-800 text-base leading-6 tracking-wide self-stretch mt-6 max-md:mt-10">
                        Email
                    </label>
                    <input
                        className="items-center border border-[color:var(--Grey-Mid,#999)] bg-white self-stretch flex justify-between gap-3.5 mt-2 h-10 pl-2 rounded-xl border-solid max-md:px-5"
                        placeholder="user@ulrc.go.ug"
                        value={user.email}
                        type='email'
                        onChange={e => setUser({ ...user, email: e.target.value })}
                    />
                    <label className="text-neutral-800 text-base leading-6 tracking-wide self-stretch mt-4">
                        Password
                    </label>
                    <div className='border border-[color:var(--Grey-Mid,#999)] bg-white flex items-center rounded-xl'>
                            <input
                                value={user.password}
                                type={isPasswordVisible ? 'text' : 'password'}
                                onChange={e => setUser({ ...user, password: e.target.value })}
                                className='h-10 pl-2 w-80 rounded-l-lg outline-none ml-1' placeholder='xxxxxxxx' />
                            <div
                                className='flex items-center justify-center w-16 h-10 rounded-r-lg cursor-pointer bg-white'
                                onClick={() => setPasswordVisible(!isPasswordVisible)}
                            >
                                {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                            </div>
                        </div>
                    <button
                        className="text-white text-base leading-6 whitespace-nowrap justify-center items-center bg-red-600 self-stretch mt-4 px-5 py-3 rounded-2xl"
                        onClick={handleSubmit}
                    >
                        {loading ? "Please Wait..." : "LOGIN"}
                    </button>
                </section>
            </main>
            <footer className="bg-bgBlue self-stretch relative flex flex-col pt-14 pb-20 px-5 max-md:max-w-full max-md:mt-10 border-t">
                <div className="md:container mx-auto">
                    <hr className="bg-zinc-400 bg-opacity-60 self-center h-[1px] max-md:max-w-full" />
                    <div className="self-center md:flex w-full  items-start md:justify-between gap-5 mt-7">
                        <div className="text-white text-base opacity-80">
                            Copyright &copy; 2023 Uganda Law Reform Commission.
                        </div>
                        <div className="flex gap-3 md:gap-5 mt-7 md:mt-0 flex-col md:flex-row text-center">
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Partners
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                About Treaty DB
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Glossary
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Blog
                            </a>
                            <a href="#" className="text-slate-100 text-base leading-[150%]">
                                Terms of Use
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default LoginForm